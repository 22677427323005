<template>
    <PageHeader :title="title" />

    <!-- компонент таблиці -->
    <tablecustom
        :dataParams="dataParams"
        :objParams="objParams"
        @search="searchB" 
        :columns="columns" 
        :rows="rows"
        :pages="objPages"
        :showpag="true"
        @open="open" 
        @getdata="getdata"
        @changelimit="changelimit"
        @changeFilter="changeFilter" 
    />

    <!-- картка клієнта -->
    <viewcard 
      v-if="showModal == true" 
      @call="call"
      @close="showModal = false" 
      :objcard="objcard"
    ></viewcard>
    
</template>

<script>
import PageHeader from "@/components/page-header";
import tablecustom from '@/components/globaltable/index';
import viewcard from './viewnew/index';
import { SuperAdmin } from '@/API.js';
import { storeS } from "@/store";
import { getFlag } from '@/usabilityScripts/globalMutate.js'

let apiServe = new SuperAdmin();

export default {
components: {
    PageHeader,
    tablecustom,
    viewcard
},
data() {
    return {
        title: this.$t('AllAccounts'),
        search: '',
        showModal: false,
        dataParams: {
            status: false,
            page: "AllAccounts"
        },
        objParams:{
            page: '1',
            pageLimit: '10',
            searchMethod: '',
            search: '',
        },
        columns: [
            {
                name: this.$t('AccountID'),
                text: "accountId",
                align: "left",
                status: false
            },
            {
                name: this.$t("country"),
                text: "country",
                align: "left",
                status: true,
                mutate: (item) => this.getFlag(item.title)
            },
            {
                name: this.$t("name"),
                text: "userName",
                align: "left",
                status: true,
                mutate: (item) => item ? item : '-'
            },
            {
                name: this.$t("phone_num"),
                text: "phone",
                align: "right",
                status: true
            },
        ],
        rows: [],
        objPages: {},
        objcard: {},
    };
},
created() {
    this.getdata();
},
methods: {
    call(e) {
        
        localStorage.setItem('actualCall', e)
        this.eventBus.emit('actualCall', {
                status: true
            })
    },
    getdata(page){
        this.objParams.page = page != undefined ? page : '1';
        apiServe.getList(this.objParams).then(result => {
            if(result.status == 'done') {
                this.rows = result.data.accounts
                var pages = {
                    links: result.data.pages.items,
                    page: 1,
                    show: result.data.show,
                    total: result.data.total
                }
                this.objPages = pages;
            } else if(result.status == 'error'){
                this.$toast.error(this.$t('error') + "# 1800");
            }
        })
    },
    open: function(e){
        apiServe.getCard(e.phone).then(result => {
            if(result.status == 'done') {
                this.objcard = result.data
                this.showModal = true
            }
        })
    },
    changeFilter(name, value){
        console.log('name || value', name + ' || ' + value);
        if(!name.type && value != undefined){
            if(name == 'pr'){
                this.objParams['pr1'] = value[0];
                this.objParams['pr2'] = value[1];
            } else if (name == 'countryFilter') {
                var arrCountry = [];
                arrCountry.push(value)
                console.log('arr', arrCountry);
                this.objPages[name] = arrCountry
            } else {
                this.objParams[name] = value;
            }
        this.getdata();
        }
    },
    searchB(e){
        try{
            var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if(emailRegex.test(e)){
                // this.setSearchMethod("email");
                this.objParams.searchMethod = 'email';
                this.objParams.search = e;
                this.getdata();
            }
        }catch(err){
            this.$toast.error(this.$t('error'))
        }

        try{
            //было бы неплохо почистить номер телефона от скобок дефисов и пробелов
            const search = e.replace(/\D/g, "");
            //это скорей всего телефон
            if(search.length == 12){
                this.objParams.search = search;
                this.objParams.searchMethod = 'phone';
                this.getdata();
            }
        }catch(err){
            this.$toast.error(this.$t('error'))
        }

        try{
            if(e.length > 31){
                this.objParams.search = e;
                this.objParams.searchMethod = 'accountId';
                this.getdata();
            }
        }catch(err){
            this.$toast.error(this.$t('error'))
        }

    },
    changelimit: function(limit){
        this.objParams.pageLimit = limit;
        this.getdata();
    },
    getFlag(e){
        return getFlag(e)
    }
},
computed: {
    user() {
        return storeS.userbase
    },
    perms() {
        return storeS.perms
    }
},
mounted() {
    this.eventBus.on('saveAcc', (status) => {
        if(status == true) {
            this.getdata();
        }
    })
}
};
</script>

<style scoped>
.tr_etner:hover {
cursor: pointer;
background: #f3f3f9;
}
</style>
